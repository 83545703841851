import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BankAccount,
    BankDetails,
    BankDetailsProvider,
    GtmEvent,
    GtmService,
    NotificationService,
    Redeem, RedeemPaymentMethod,
    RedeemProvider,
    RedeemType,
    UserBalanceDetails
} from "../../../core";
import {UserInfoService} from "../../../core/store";
import {combineLatest, Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ModalService} from "../../services";
import {RedeemSuccessModalComponent} from "../redeem-success-modal/redeem-success-modal.component";
import {take, takeUntil} from "rxjs/operators";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EventBus} from "../../../core/infrastructure";
import {EVENT_TYPES} from "../../constants";
import {BankDetailsModalComponent} from '../bank-details-modal';
import {environment} from "../../../../environments/environment";

declare var PNM: any;

@Component({
    selector: 'app-redeem-coins',
    templateUrl: './redeem-coins.component.html',
    styleUrls: ['./redeem-coins.component.scss']
})
export class RedeemCoinsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public redeemInProgress: boolean = false;
    public showIframe: boolean = false;

    public form!: UntypedFormGroup;

    public iframeUrl: SafeResourceUrl | null = null;
    public sweepCoins: UserBalanceDetails | null = null;
    public bankAccounts: BankAccount[] = [];
    public details: BankDetails | null = null;
    public useBankDetails: boolean = false;
    public activePaymentMethods: RedeemPaymentMethod[] = [];
    public transactionId: string = "";
    public isTestingPNM: boolean = true;

    public readonly RedeemType = RedeemType;

    constructor(
        private readonly eventBus: EventBus,
        private readonly redeemProvider: RedeemProvider,
        private readonly notificationService: NotificationService,
        private readonly bankDetailsProvider: BankDetailsProvider,
        private readonly gtmService: GtmService,
        private readonly userInfoService: UserInfoService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly modalService: ModalService,
        private readonly sanitizer: DomSanitizer
    ) {
        let storageValue = localStorage.getItem('PNM_TESTING');

        if (storageValue === null) {
            storageValue = environment.ENVIRONMENT === "development" ? 'TRUE' : 'FALSE';
            localStorage.setItem('PNM_TESTING', storageValue)
        }

        this.isTestingPNM = storageValue === 'TRUE'
    }

    get balance() {
        return this.sweepCoins?.balance || 0;
    }

    get lockedBalance() {
        return this.sweepCoins?.lockedBalance || 0;
    }

    get totalBalance() {
        return this.balance + this.lockedBalance;
    }

    get isFormValid() {
        return this.form?.valid || false;
    }

    get hasAmount() {
        return this.form?.get("amount")?.valid || false;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngOnInit(): void {
        this.gtmService.pushEvent(GtmEvent.OpenedRedeemScreen);

        combineLatest([
            this.userInfoService.getBalances(),
            this.bankDetailsProvider.getBankAccounts(),
            this.bankDetailsProvider.getBankDetails()
        ]).pipe(
            takeUntil(this.destroy$),
        ).subscribe(([balanceDetails, accounts, bankDetails]) => {
            if (balanceDetails === undefined) return;

            this.sweepCoins = balanceDetails.find(balance => balance.currency === 5001) || null;
            this.bankAccounts = accounts ?? [];
            this.details = bankDetails;

            this.buildForm();
        });

        this.eventBus.OnChange(EVENT_TYPES.BANK_DETAILS).pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            this.showIframe = false;

            this.bankDetailsProvider.getBankAccounts().subscribe((accounts) => {
                this.bankAccounts = accounts;

                this.buildForm();
            })
        })
    }

    close(): void {
        this.showIframe = false;
        this.modalService.closeAll();

        PNM.close();
    }

    getErrorMessage(formControl: any) {
        if (formControl.hasError('required'))
            return 'This field is required.';

        if (formControl.hasError('min'))
            return 'Value should be at least 100SC.';

        if (formControl.hasError('max'))
            return 'Value can\'t be more than your balance'

        return ""
    }

    redeem(type: RedeemType | null = null): void {
        if (this.redeemInProgress || !this.hasAmount)
            return;

        this.redeemInProgress = true

        if (type === null) {
            type = this.useBankDetails ? RedeemType.BankDetails : RedeemType.NuveiIbt;
        }

        const data: Redeem = {
            amount: this.form.get("amount")?.value,
            type: type,
            upo: this.useBankDetails ? null : this.bankAccounts.find(b => b.upoId === this.form.get("account")?.value)!,
        };

        this.redeemProvider.redeem(data).subscribe({
            next: (result) => {
                this.transactionId = result.transactionId;

                if (result.secureSmartToken) {
                    const pnmConfig = {
                        "order_token": result.secureSmartToken,
                        "callback": this.handlePNMCallback,
                        "show_header": true,
                        "auto_resize": true,
                        "language": "en",
                        "actions": {
                            "perform-redeem-ach": {
                                "action": "tokenize",
                                "ach": true,
                            }
                        }
                    }

                    PNM.init(pnmConfig);
                    PNM.launch('perform-redeem-ach');

                    return;
                }

                if (result.activePaymentMethods.length > 0) {
                    this.activePaymentMethods = result.activePaymentMethods;
                    return;
                }

                if (result.redirectUrl) {
                    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.redirectUrl);
                    this.showIframe = true;

                    return;
                }

                this.completeRedeem();
            },
            error: (err) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: err.error.detail || err.message
                });
                setTimeout(() => {
                    this.redeemInProgress = false;
                }, 500);
            },
            complete: () => {
                setTimeout(() => {
                    this.redeemInProgress = false;
                }, 500);
            },
        });
    }

    linkNewAccount() {
        if (!this.hasAmount) return;

        this.showIframe = true;

        this.bankDetailsProvider.linkBankAccounts({
            amount: this.form.get("amount")?.value
        }).subscribe({
            next: (result) => {
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.url);
            },
            error: (err) => {
                this.showIframe = false;
                this.notificationService.showNotification({
                    type: 'error',
                    message: err.error.detail
                });
            }
        })
    }

    addBankDetails(): void {
        this.modalService.open(BankDetailsModalComponent);
    }

    applyBankDetails(): void {
        this.useBankDetails = !this.useBankDetails;

        const accountControl = this.form.get('account');
        if (accountControl) {
            accountControl.setValidators(this.useBankDetails ? null : Validators.required);
            accountControl.updateValueAndValidity();
        }
    }

    handlePNMCallback = (data: any) => {
        if (!data.status) return;

        switch (data.status) {
            case "complete": {
                this.sendPaymentMethod(null);

                break;
            }

            case "info": {
                if (data.error === "Modal closed by user") {
                    PNM.close();
                }

                break;
            }

            default: {
                PNM.close();
                break;
            }
        }
    }

    selectPaymentMethod(method: RedeemPaymentMethod) {
        this.sendPaymentMethod(method);
    }

    private sendPaymentMethod(method: RedeemPaymentMethod | null) {
        this.redeemProvider.pnmMethod({
            paymentMethodIdentifier: method?.paymentMethodIdentifier ?? null,
            transactionId: this.transactionId
        }).pipe(
            take(1)
        ).subscribe({
            next: () => {
                this.completeRedeem();
            },
            error: (err) => {
                this.close();

                this.notificationService.showNotification({
                    type: 'error',
                    message: err.error.detail || err.message
                });
            }
        })
    }

    private completeRedeem() {
        this.gtmService.pushEvent(GtmEvent.SubmittedRedeemRequest);

        this.close();
        this.modalService.open(RedeemSuccessModalComponent);

        this.userInfoService.reloadBalances();
    }

    private buildForm(): void {
        const defaultBankAccount = this.bankAccounts?.length > 0
            ? this.bankAccounts[0].upoId
            : null

        this.form = this.formBuilder.group({
            amount: [null, [
                Validators.required,
                Validators.min(100),
                Validators.max(this.balance),
            ]],
            account: [defaultBankAccount, [
                Validators.required,
            ]],
        })
    }
}
