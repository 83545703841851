<div
  *ngIf="isAuthenticated"
  class="coins-container"
  [class.minimize]="isMinimized"
  [class.horizontal]="horizontalView">
  <h2 *ngIf="!isMobileViewGame && !hideTitle" class="coins-container__title">
    Switch between coins
  </h2>
  <div class="coins-container__balance" [class]="balancePosition">
    <div *ngIf="!hideTitle" class="coins" [class]="balancePosition">
      {{ balance | number }}
    </div>
    <div *ngIf="hideTitle" class="coins" [class]="balancePosition">
      <span>{{ balance | numberFormat }}</span>
      <div class="plus-button" (click)="openStore()">
        <i class="u_plus-circle"></i>
      </div>
    </div>
    <div
      class="toggle"
      [class.landscape]="isLandscapeView"
      [class.minimize]="isMinimized"
      (click)="toggleCoins()"
      title="Sweeps Coins coming soon">
      <label
        *ngFor="let item of currencies"
        class="radio-button"
        [class.selected]="currency === item">
        <span
          class="coin-image"
          [attr.data-attribute]="getCurrency(item)"></span>
        <span>{{ getCurrency(item) }}</span>
      </label>
    </div>
  </div>
</div>