<ng-container *ngIf="isSuccess">
    <img class="status-img" src="../../../../../../assets/images/logo/logo-dark.svg" alt="sweep-logo">
    <img class="status-img" src="../../../../../../assets/images/coin-success.svg" alt="success">

    <label class="purchase-title">Transaction successful</label>
    <label class="purchase-info">{{coinTitle}}</label>
</ng-container>

<ng-container *ngIf="isFail">
    <img class="status-img" src="../../../../../../assets/images/logo/logo-dark.svg" alt="sweep-logo">
    <img class="status-img" src="../../../../../../assets/images/close.svg" alt="progress">

    <label class="purchase-title">Transaction failed</label>
    <label class="purchase-info" *ngIf="!data.message">Transaction is {{statusText.toLowerCase()}}</label>
    <label class="purchase-info" *ngIf="data.message">{{data.message}}</label>
</ng-container>

<ng-container *ngIf="isPending">
    <img class="status-img" src="../../../../../../assets/images/logo/logo-dark.svg" alt="sweep-logo">
    <img class="status-img" src="../../../../../../assets/images/loading.svg" alt="progress">

    <label class="purchase-title">Transaction in progress</label>
    <label class="purchase-info">Please don’t close this window.</label>
</ng-container>

<div class="purchase-actions" *ngIf="!isPending">
    <button class="btn btn-large" (click)="close()" aria-label="Back to games">
        <span>Close</span>
    </button>
</div>
