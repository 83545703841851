<div class="modal radial-bg">
  <header class="modal__header">
    <h1>Coin Store</h1>
    <i
      (click)="close()"
      aria-describedby="Close button"
      class="u_times-circle"></i>
  </header>
  <div class="modal__content">
    <app-redeem-cards></app-redeem-cards>
    <app-package-buttons></app-package-buttons>
    <div class="footer-content">
      <div class="payment-icons">
        <img
          src="assets/images/cards/white/americanexpress-svgrepo-com.svg"
          alt="americanexpress" />
        <img src="assets/images/cards/white/visa-svgrepo-com.svg" alt="visa" />
        <img
          src="assets/images/cards/white/mastercard-svgrepo-com.svg"
          alt="mastercard" />
        <img
          src="assets/images/cards/white/discover-svgrepo-com.svg"
          alt="discover" />
        <img
          src="assets/images/cards/white/bank-transfer-logo-svgrepo-com.svg"
          alt="bank-transfer" />
      </div>

      <div class="info">
        <span>
          It's always FREE to play with Sweeps Coins. <br />
          No purchase necessary. See
          <a (click)="navigateTo('pages/sweeps-rules')"><b>Sweeps Rules</b></a>
          and our
          <a (click)="navigateTo('pages/terms')"><b>Terms & Conditions</b></a
          >.
        </span>
      </div>
    </div>
  </div>
</div>