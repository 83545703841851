import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {HubConnection} from "@microsoft/signalr";

const createInitialState = (): HubStateEntity => {
    return {
        hub: null,
    };
}

export interface HubStateEntity {
    hub: HubConnection | null,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'casinoLobby', resettable: true})
export class HubStore extends Store<HubStateEntity> {
    constructor() {
        super(createInitialState());
    }

    setHub(hub: HubConnection | null) {
        this.update({
            hub: hub
        })
    }
}
