import { IConfig } from '../app/core';

export const environment: IConfig = {
  ENVIRONMENT: 'production',
  CURRENT_URL: 'https://www.luckylagoon.com',
  CANONICAL_DOMAIN: 'https://www.luckylagoon.com',
  CURRENT_TITLE: 'luckylagoon',
  INGENIOUS: {
    TRC_DOMAIN: 'test',
    ADV_ID: '94093',
    GDPR: '0',
    GDPR_CONSENT: '0',
  },
  ENDPOINTS: {
    GAMES: {
      URL: 'https://cdn.softswiss.net',
      CASINO_ID: 'luckylagoon',
      AUTH_TOKEN: 'mU8nBgFMb-v?f9yd@3Zc*fCjU2C$qyNQ',
      GCP_URL: 'https://casino.cur.a8r.games',
      SCRIPT_URL: 'https://cdn.launcher.a8r.games/connector.js',
    },
    API: {
      URL: '/api',
      SSR_URL: 'https://www.luckylagoon.com/api',
    },
    CMS: {
      URL: '/cms',
      SSR_URL: 'https://cms.luckylagoon.com',
      CMS_TOKEN: 'f52902f68ee2ef7578b22a0f07211c0fb05979b9f447c42857e049467c837a4f1a66a0164f14eb7e0d9ef5755481b43ec12f204470c25bae95189f7e89ad52f0210fd62706a770db78eb24b52a4c09a88603db888760bdebda2b941d0224e4e291d207d4d522e7295419e8254a5d01ad3be04a2b032acd7b27b2926a332066ea',
    },
  },
  CLIENT_ID: 'CfResourceOwnerClientIdMobile',
  CLIENT_SECRET: 'cf_resource_owner_flow_secret_222_mobile',
  FACEBOOK_APP_ID: 'test',
  SENTRY_DSN: 'https://bcf2fe00bc8bc49aff78780f2b68d0e5@o4508024233656320.ingest.us.sentry.io/4508044034244608',
  FEATURES_SETTINGS: {
    ACTIVITY: 'true',
    AUDIT: 'true',
    BONUS: 'true',
    GEOLOCATION: 'true',
    KYC: 'true',
    NOTIFICATION: 'true',
    REPORTING: 'true',
    RESPONSIBLE_GAMBLING: 'true',
    WALLET: 'true',
  },
  FLYKK_SETTINGS: {
    SCRIPT_URL: 'https://stage-verify.isignth.is/js/isx-embed.js?profiles=embedded',
  },
  CHECKOUT_SETTINGS: {
    PUBLIC_KEY: 'pk_sbox_xcpcgay42mplfdrfgjzxf4baduk',
  },
  PAYPAL_SETTINGS: {
    CLIENT_ID: 'test',
  },
  SPREEDLY_SETTINGS: {
    SCRIPT_URL: 'https://core.spreedly.com/iframe/iframe-v1.min.js',
    TOKEN: 'test',
  },
  GOOGLE_KEY: 'test',
  GOOGLE_PAY_SETTINGS: {
    MERCHANT_ID: 'test',
    MERCHANT_NAME: 'Demo Merchant',
  },
  RECAPTCHA: {
    SITE_KEY: 'test',
  },
  PAYNEARME_SETTINGS: {
    SCRIPT_URL: '',
  },
};