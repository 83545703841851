<div class="purchase-card purchase-card-full">
  <span class="purchase-card__title">Payment Method</span>
</div>

<label class="purchase-label">Purchase Summary</label>
<div class="purchase-card">
    <app-coin-card [disabledButton]="true" [coin]="coin"></app-coin-card>
</div>

<div class="payment">
  <ng-container *ngIf="!paymentInProgress">
    <label class="payment-label">Pay via:</label>

    <div class="mb-8" *ngFor="let method of paymentMethods">
      <button
        *ngIf="!isGooglePay(method); else googleButton"
        class="btn btn-block btn-large payment-button"
        (click)="startPayment(method)">
        <img
          *ngIf="!method.title; else methodTitle"
          src="assets/images/payment-visa.svg"
          alt="Mastercard" />
        <ng-template #methodTitle>
          <span>
            <i class="u_credit-card mr-12"></i>
            {{ method.title }}
          </span>
        </ng-template>

        <i class="u_arrow-circle-right"></i>
      </button>
      <ng-template #googleButton>
        <app-google-payment
          [coin]="coin"
          (onComplete)="onComplete($event)"
          (onClose)="onClose()"></app-google-payment>
      </ng-template>
    </div>
  </ng-container>
  <ng-container>
    <app-nuvei-ibt-payment
      [coin]="coin"
      (onComplete)="onComplete($event)"
      (onClose)="onClose()">
    </app-nuvei-ibt-payment>
    <app-nuvei-payment
      [coin]="coin"
      (onComplete)="onComplete($event)"
      (onClose)="onClose()">
    </app-nuvei-payment>
    <app-flykk-payment
      [coin]="coin"
      (onComplete)="onComplete($event)"
      (onClose)="onClose()">
    </app-flykk-payment>
    <app-forumpay-payment
      [coin]="coin"
      (onComplete)="onComplete($event)"
      (onClose)="onClose()">
    </app-forumpay-payment>
    <app-checkout-payment
      [coin]="coin"
      (onComplete)="onComplete($event)"
      (onClose)="onClose()"></app-checkout-payment>
    <app-paynearme-payment
      [coin]="coin"
      (onComplete)="onComplete($event)"
      (onClose)="onClose()">
    </app-paynearme-payment>
  </ng-container>
</div>

<app-button
  (onButtonClicked)="close()"
  type="danger"
  text="Cancel Payment"></app-button>
<app-button
  class="mt-10"
  (onButtonClicked)="back()"
  type="normal"
  text="Back"></app-button>