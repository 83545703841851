<div
  class="navigation-bar"
  [class.minimize]="isMinimized"
  [class.full-screen]="isModal">
  <div class="close-button-container" *ngIf="isModal">
    <button class="btn btn-icon close-button" (click)="closeMenu()">
      <i aria-describedby="Close menu" class="u_subject"></i>
    </button>
  </div>
  <div class="navigation-bar__content" *ngIf="isLoaded">
    <div class="navigation-bar__content__header">
      <a class="navigation-bar__content__link" [routerLink]="'/'">
        <img
          class="navigation-bar__content__logo"
          *ngIf="!isMinimized"
          src="../../../../assets/images/logo/logo-dark.svg"
          aria-label="Logo"
          alt="lucky-sweep" />
        <img
          class="navigation-bar__content__logo"
          *ngIf="isMinimized"
          src="../../../../assets/images/logo/logo-minimized.svg"
          aria-label="Logo"
          alt="lucky-sweep" />
      </a>
      <ng-container *ngIf="isAuthenticated">
        <div class="progress-bar">
          <app-progress-bar></app-progress-bar>
        </div>
        <div *ngIf="userDetails" class="username" (click)="openDetails()">
          {{ userDetails?.username }}
        </div>
        <div class="toggle-coins">
          <app-toggle-coins
            [isMinimized]="isMinimized"
            [balancePosition]="'bottom'"></app-toggle-coins>
        </div>
      </ng-container>
      <div *ngIf="!isAuthenticated" class="navigation-bar__content__buttons">
        <button
          (click)="navigateWithPreservedQueryParams('/auth/sign-up')"
          class="btn btn-small btn-blue">
          <i class="u_user-circle"></i>
          <span *ngIf="!isMinimized" class="label">Sign up</span>
        </button>
        <button
          (click)="navigateWithPreservedQueryParams('/auth/sign-in')"
          class="btn btn-small btn-blue mt-8">
          <i class="u_arrow-circle-right"></i>
          <span *ngIf="!isMinimized" class="label">Log in</span>
        </button>
      </div>
    </div>
    <div class="navigation-bar__content__sticky">
      <div class="navigation-bar__content__actions">
        <a *ngIf="isAuthenticated"
          class="coin-store-btn"
          (click)="navigateTo('coin-store')"
        >
            <span class="icon-container">
                <img src="../../../../assets/images/coin_store.svg">
            </span>
            <span>Get Coins</span>
        </a>
        <a
          *ngFor="let link of navLinks"
          class="link-item"
          routerLinkActive="active-link"
          (click)="navigateTo(link.Url)"
          [ngClass]="{ 'active-link': selectedRoute == link.Url }"
          [matTooltip]="link.Title"
          matTooltipPosition="after"
          [matTooltipDisabled]="!isMinimized">
          <span class="icon-container">
            <img
              [attr.aria-label]="link.Title"
              [src]="link.Icon.url"
              [alt]="link.Title" />
          </span>
          <span *ngIf="!isMinimized" class="title">{{ link.Title }}</span>
        </a>
      </div>
      <div class="navigation-bar__content__footer">
        <a
          class="link"
          routerLinkActive="active-link"
          href="javascript:void(0)"
          matTooltip="Help"
          matTooltipPosition="after"
          [matTooltipDisabled]="!isMinimized"
          (click)="help()">
          <span class="icon-container">
            <i aria-describedby="Live chat" class="icon u_chat-info"></i>
          </span>
          <span *ngIf="!isMinimized" class="title">Help</span>
        </a>
        <a
          *ngIf="isAuthenticated"
          class="link"
          routerLinkActive="active-link"
          href="javascript:void(0)"
          matTooltip="Logout"
          matTooltipPosition="after"
          [matTooltipDisabled]="!isMinimized"
          (click)="logout()">
          <span class="icon-container">
            <i class="icon u_signin"></i>
          </span>
          <span *ngIf="!isMinimized" class="title">Logout</span>
        </a>
      </div>
      <div class="navigation-bar__content__toggle" *ngIf="!isModal">
        <div class="toggle-icon" (click)="toggleSidebar()">
          <i *ngIf="!this.isMinimized" class="u_left-indent-alt"></i>
          <i *ngIf="this.isMinimized" class="u_right-indent-alt"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="game-header-landscape">
    <div *ngIf="isAuthenticated">
      <app-toggle-coins
        [isMobileViewGame]="true"
        [isLandscapeView]="true"
        [balancePosition]="'bottom'"></app-toggle-coins>
    </div>
    <div [class.full-buttons]="!isAuthenticated">
      <div class="mb-12">
        <button class="btn btn-icon" (click)="closeMenu()">
          <i aria-describedby="Close menu" class="u_times"></i>
        </button>
      </div>
      <div class="mb-12">
        <button class="btn btn-icon colored" (click)="closeMenu()">
          <i aria-describedby="Close menu" class="u_subject"></i>
        </button>
      </div>
    </div>
  </div>
</div>