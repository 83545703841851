<div class="modal">
  <div class="modal__header">
    <img
      class="logo"
      src="../../../../assets/images/logo/logo-dark.svg"
      alt="Casino logo" />
    <button class="close" (click)="close()" aria-label="Close button">
      <i class="u_times-circle"></i>
    </button>
  </div>

  <div class="modal__content">
    <app-sign-in-form (onRedirect)="close()"></app-sign-in-form>
  </div>
</div>