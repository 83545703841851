import { Component, Input, OnInit } from '@angular/core';
import { CoinPackageService } from '../../../core/services/coin-package.service';
import { ModalService } from '../../services';

@Component({
  selector: 'app-free-coins-modal',
  templateUrl: './free-coins-modal.component.html',
  styleUrls: ['./free-coins-modal.component.scss'],
})
export class FreeCoinsModalComponent implements OnInit {
  // TODO: Check if is LC once the SC functionality is implemented
  public isLC: boolean = true;
  constructor(
    private readonly coinPackageService: CoinPackageService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {}

  public close(): void {
    this.modalService.closeAll();
  }

  openStore() {
    this.close();
    this.coinPackageService.openCoinStoreModal();
  }
}