<div class="payment-container">
  <div *ngIf="showButton && !isFormOpen">
    <button
      class="btn btn-block btn-extra-large btn-blue"
      (click)="initPayment()">
      <i class="u_credit-card ml-14"></i>
      <span>Checkout.com</span>
    </button>
  </div>
  <div class="relative-visibility" [class.visible]="isFormOpen">
    <div class="icons-header">
      <img src="assets/images/cards/visa.svg" alt="Mastercard" />
      <img src="assets/images/cards/AmericanExpress.svg" alt="Mastercard" />
      <img src="assets/images/cards/mastercard-logo.svg" alt="Mastercard" />
    </div>
    <form
      [formGroup]="paymentForm"
      id="payment-form"
      method="POST"
      action="https://merchant.com/charge-card">
      <div class="mb-24">
        <div class="input-container__label">Card holder</div>
        <div class="input-container card-holder">
          <div class="icon-container">
            <i class="u_user-circle"></i>
          </div>
          <input
            class="card-holder-input"
            type="text"
            formControlName="cardholderName"
            placeholder="John Smith"
            onkeydown="return /[a-zA-Z\s]/.test(event.key)" />
        </div>
        <div class="input-container__label">Card number</div>
        <div class="input-container card-number">
          <div class="icon-container">
            <i class="u_credit-card"></i>
          </div>
          <div class="card-number-frame"></div>
        </div>

        <div class="date-and-code">
          <div>
            <div class="input-container__label">Expiry date</div>
            <div class="input-container expiry-date">
              <div class="icon-container">
                <i class="u_calender"></i>
              </div>
              <div class="expiry-date-frame"></div>
            </div>
          </div>

          <div>
            <div class="input-container__label">Security code</div>
            <div class="input-container cvv">
              <div class="icon-container">
                <i class="u_shield"></i>
              </div>
              <div class="cvv-frame"></div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-block btn-extra-large btn-green mb-6"
        [class.loading]="inProgress"
        [disabled]="!canSubmit || inProgress"
        (click)="submit()">
        <span>Pay</span>
      </button>
      <button class="btn btn-block btn-outlined" (click)="close()">
        <span>Cancel</span>
      </button>
    </form>
  </div>
</div>
<div id="iframeModal" class="modal-container" [class.hidden]="!show3dSecure">
  <div class="modal-backdrop"></div>
  <div class="modal">
    <div class="modal__header">
      <h1>Checkout</h1>
      <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>
    <div class="modal__content">
      <iframe
        id="checkoutIframe"
        *ngIf="iframeUrl !== null"
        [src]="iframeUrl"></iframe>
    </div>
  </div>
</div>