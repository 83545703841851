import {Injectable} from "@angular/core";
import {Query} from "@datorama/akita";
import {HubStateEntity, HubStore} from "./hub.store";

@Injectable({providedIn: 'root'})
export class HubQuery extends Query<HubStateEntity> {
    public hub$ = this.select(item => item.hub);

    constructor(
        private readonly _store: HubStore
    ) {
        super(_store);
    }
}
