import {Injectable} from "@angular/core";
import {HubQuery} from "./hub.query";
import {HubStore} from "./hub.store";
import {take, withLatestFrom} from "rxjs/operators";
import {AccountProvider} from "../../providers";
import {ILoyaltyLevelAdjustment, PlayerLevel} from "../../interfaces";
import * as signalR from "@microsoft/signalr";
import {environment} from "../../../../environments/environment";
import {EVENT_TYPES} from "../../../shared";
import {GtmEvent} from "../../enums";
import {FreeCoinsModalComponent} from "../../../shared/modals";
import {EventBus} from "../../infrastructure";
import {GtmService, NotificationService} from "../../services";
import {UserInfoService} from "../user";
import {AccountService} from "../account";
import {AuthService} from "../auth";
import {LevelsService} from "../levels";
import {ModalService} from "../../../shared/services";
import {HubConnection} from "@microsoft/signalr";
import {CoinPackageService} from "../../services/coin-package.service";

@Injectable({providedIn: 'root'})
export class HubService {
    constructor(
        private readonly eventBus: EventBus,
        private readonly notificationService: NotificationService,
        private readonly userInfoService: UserInfoService,
        private readonly accountProvider: AccountProvider,
        private readonly accountService: AccountService,
        private readonly gtmService: GtmService,
        private readonly authService: AuthService,
        private readonly levelsService: LevelsService,
        private readonly modalService: ModalService,
        private readonly hubQuery: HubQuery,
        private readonly hubStore: HubStore,
        private readonly coinPackageService: CoinPackageService,
    ) {
    }

    public connect() {
        if (environment.FEATURES_SETTINGS.NOTIFICATION !== 'true')
            return;

        this.authService.isAuthenticated().pipe(
            withLatestFrom(this.hubQuery.hub$),
        ).subscribe(([isAuthenticated, hub]) => {
            if (!isAuthenticated) {
                return;
            }

            if (hub === null || hub.state === signalR.HubConnectionState.Disconnected) {
                this.createHub();
                return;
            }
        })
    }

    public disconnect() {
        this.hubQuery.hub$.pipe(
            take(1)
        ).subscribe((hub) => {
            if (!hub) return;

            hub.stop().then(() => {
                this.hubStore.setHub(null);
            });
        })
    }

    private createHub() {
        const hubUrl = environment.CURRENT_URL + environment.ENDPOINTS!.API.URL + '/notification-hub';
        const hub = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, {
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => {
                    return this.authService.getActiveAccessToken();
                }
            })
            .withAutomaticReconnect()
            .build();

        hub.onclose(() => {
            this.disconnect()
        })

        this.prepareListeners(hub);

        this.hubStore.setHub(hub);
    }

    private prepareListeners(hub: HubConnection) {
        hub.start().then((value) => {
            setTimeout(() => this.accountProvider.getNotifications().subscribe(), 2000);

            hub.on(EVENT_TYPES.PLAYER_EDITED, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.PLAYER_EDITED, msg)

                this.onPlayerEdited(msg);
            });

            hub.on(EVENT_TYPES.PLAYER_LOCKED, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.PLAYER_LOCKED, msg)
            });

            hub.on(EVENT_TYPES.PLAYER_ADJUSTMENT, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.PLAYER_ADJUSTMENT, msg)

                this.onPlayerAdjustment(msg);
            });

            hub.on(EVENT_TYPES.PURCHASE_COMPLETE, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.PURCHASE_COMPLETE, msg);

                this.onPurchaseComplete(msg);
            });

            hub.on(EVENT_TYPES.FREE_COINS_RECEIVED, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.FREE_COINS_RECEIVED, msg);

                this.onFreeCoinsReceived(msg);
            });

            hub.on(EVENT_TYPES.LOYALTY_LEVEL, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.LOYALTY_LEVEL, msg);

                this.onLoyaltyLevelChange(msg)
            });

            hub.on(EVENT_TYPES.BANK_DETAILS, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.BANK_DETAILS, msg)
            });

            hub.on(EVENT_TYPES.REDEEM_COMPLETE, (msg: any) => {
                this.eventBus.Publish<boolean>(EVENT_TYPES.REDEEM_COMPLETE, msg)
            });

            hub.on(EVENT_TYPES.GAME_SPIN, (msg: any) => {
                console.log('GAME_SPIN', msg)
            });
        })
    }

    private onLoyaltyLevelChange(msg: any) {
        const response = msg.loyaltyLevel as ILoyaltyLevelAdjustment;

        if (response.nextLevelUpdate) {
            this.notificationService.showNotification({
                type: 'success',
                message: `Congratulations! You have levelled up to Level ${response.level}`
            });

            let messages = [];

            if (response.goldCoins > 0)
                messages.push(`${response.goldCoins} LC`);

            if (response.sweepCoins > 0)
                messages.push(`${response.sweepCoins} SC`);

            if (messages.length > 0) {
                this.notificationService.showNotification({
                    type: "success",
                    title: "Congratulations!",
                    message: `You have received ${messages.join(" & ")} for leveling up!`
                })
            }
        }

        this.levelsService.updatePlayerLevel({
            currentPoints: response.currentPoints,
            level: response.level,
            currentProgress: response.currentProgress,
            levelTarget: response.levelTarget
        } as PlayerLevel)
    }

    private onPlayerAdjustment(msg: any) {
        if (msg && msg.coinArea) {
            this.coinPackageService.showReceiveNotification(msg.coinArea);
        }

        this.userInfoService.reloadBalances();
    }

    private onPlayerEdited(msg: any) {
        if (msg.refresh) {
            this.accountService.refreshToken().subscribe();
        }
        if (msg.kyc) {
            this.userInfoService.loadUser();
        }
    }

    private onPurchaseComplete(msg: any) {
        if (!msg.hasError)
            this.userInfoService.reloadBalances();

        this.gtmService.pushEvent(msg.hasError ? GtmEvent.PurchaseFailed : GtmEvent.PurchaseSuccess);
    }

    private onFreeCoinsReceived(msg: any) {
        const dialogRef = this.modalService.open(FreeCoinsModalComponent);
        dialogRef.componentInstance.isLC = msg.isLc
        this.userInfoService.reloadBalances();
    }
}
