<div class="modal">
    <div class="modal__header">
        <h1>Request submitted</h1>
        <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
    </div>

    <div class="modal__content">
        <div class="redeem-content">
            <div class="redeem-logo">
                <img class="logo-img" src="../../../../assets/images/logo/logo-dark.svg" alt="sweep-logo">
                <img src="assets/images/success.png" alt="redeem">
            </div>
            <div class="redeem-content__description">
                Redeem Request submitted
            </div>
            <button class="btn btn-large btn-block" (click)="close()">
                Ok
            </button>
        </div>
    </div>
</div>
