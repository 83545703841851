<div
  class="bottom-content"
  *ngIf="showBottomContent"
  [class.hidden]="isContentHidden">
  <div
    class="bottom-content__text"
    [innerHTML]="bottomContent | safe: 'html'"></div>
  <div class="btn-container">
    <button class="btn btn-small btn-secondary" (click)="toggleHidden()">
      {{ isContentHidden ? 'Show more' : 'Show less' }}
    </button>
  </div>
</div>
<footer>
  <img
    class="logo"
    src="../../../../assets/images/logo/logo-light.svg"
    alt="logo" />
  <div *ngFor="let block of blocks">
    <div *ngIf="block.__component === FooterBlocksTypes.HTML">
      <div
        class="page-item"
        [innerHTML]="$html(block).Content | safe: 'html'"></div>
    </div>
    <div class="links" *ngIf="block.__component === FooterBlocksTypes.Links">
      <a
        *ngFor="let link of $links(block).Items"
        [href]="link.link"
        [attr.aria-label]="link.title">
        <img *ngIf="link.icon" [src]="link.icon.url" [alt]="link.title" />
        <span *ngIf="link.showTitle">{{ link.title }}</span>
      </a>
    </div>
  </div>
</footer>