import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  CoinPackageOffer,
  NotificationService,
  PaymentName,
} from '../../../../core';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { EventBus } from '../../../../core/infrastructure';
import { EVENT_TYPES } from '../../../constants';
import { TransactionStatus } from '../../../../core/enums/transaction';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PaymentsService } from '../../../../core/store';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/shared/services';

declare var Frames: any;

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  private completeSubscription$: Subscription | null = null;

  public paymentForm!: FormGroup;
  public validations = new Map<string, boolean>();
  public isFormOpen = false;
  public inProgress = false;
  public show3dSecure = false;
  public canSubmit = false;
  public iframeUrl: SafeResourceUrl | null = null;

  private events: Function[] = [];

  @Input()
  public coin!: CoinPackageOffer;

  @Input()
  public showButton: boolean = false;

  @Output()
  public onComplete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly paymentsService: PaymentsService,
    private readonly eventBus: EventBus,
    private readonly sanitizer: DomSanitizer,
    private renderer2: Renderer2,
    private fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.eventBus
      .OnChange<number>(EVENT_TYPES.PAYMENT_STARTED)
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.paymentsService.getProviders())
      )
      .subscribe(([providerId, providers]) => {
        if (
          providers.find(p => p.id === providerId)?.name ===
          PaymentName.CHECKOUT
        ) {
          this.initPayment();
        }
      });

    this.paymentForm = this.fb.group({
      cardholderName: ['', Validators.required],
    });

    this.paymentForm
      .get('cardholderName')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(newValue => {
        Frames.currentConfig.cardholder.name = newValue;
        this.updateCanSubmit();
      });
  }

  public initPayment() {
    this.inProgress = false;
    this.isFormOpen = true;

    this.validations.clear();

    this.bindFrameEvents();
    this.initFrames();

    this.completeSubscription$ = this.eventBus
      .OnChange(EVENT_TYPES.PURCHASE_COMPLETE)
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg: any) => {
        if (msg.hasError) {
          this.notificationService.showNotification({
            type: 'error',
            message: 'Transaction failed',
          });
          return;
        }

        this.notificationService.showNotification({
          type: 'success',
          message: 'Transaction successful',
        });
      });
  }

  public submit() {
    this.inProgress = true;
    Frames.submitCard();
  }

  public clear() {
    this.canSubmit = false;
    this.show3dSecure = false;
    this.inProgress = false;
    this.isFormOpen = false;
    this.iframeUrl = null;

    Frames.removeAllEventHandlers(Frames.Events.CARD_TOKENIZED);
    Frames.removeAllEventHandlers(Frames.Events.FRAME_VALIDATION_CHANGED);

    this.events.forEach(event => {
      event();
    });

    this.completeSubscription$?.unsubscribe();
  }

  public close() {
    if (this.iframeUrl) {
      this.modalService.closeAll();
    }
    this.clear();

    this.onClose.emit();
  }

  public ngOnDestroy(): void {
    this.close();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private bindFrameEvents() {
    const cardTokenizedEvent = this.renderer2.listen(
      'window',
      'CARD_TOKENIZED',
      ({ detail }) => {
        this.inProgress = true;

        this.paymentsService
          .initiateCheckoutPayment({
            coinPackageId: this.coin.id,
            token: detail.token,
          })
          .subscribe({
            next: response => {
              if (response.url) {
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  response.url
                );
                this.show3dSecure = true;
              } else {
                this.onComplete.emit({
                  status: TransactionStatus.Pending,
                });
              }
            },
            error: response => {
              this.notificationService.showNotification({
                type: 'error',
                message: response?.error?.detail ?? response,
              });
            },
          });
      }
    );

    const frameValidationEvent = this.renderer2.listen(
      'window',
      'FRAME_VALIDATION_CHANGED',
      ({ detail }) => {
        this.validations.set(detail.element, detail.isValid);
        this.updateCanSubmit();
      }
    );

    this.events = [cardTokenizedEvent, frameValidationEvent];
  }

  private initFrames() {
    Frames.init({
      publicKey: environment.CHECKOUT_SETTINGS?.PUBLIC_KEY,
      //debug: true,
      style: {
        base: {
          color: '#1A1834',
          fontSize: '16px',
        },
        valid: {
          color: '#00E074',
        },
        invalid: {
          color: '#FB3838',
        },
        placeholder: {
          base: {
            color: '#82afc1',
          },
        },
        autofill: {
          backgroundColor: '$blue-lightest',
          width: '90%',
        },
      },
    });

    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, (event: any) => {
      window.dispatchEvent(
        new CustomEvent('CARD_TOKENIZED', {
          detail: event,
        })
      );
    });

    Frames.addEventHandler(
      Frames.Events.FRAME_VALIDATION_CHANGED,
      (event: any) => {
        window.dispatchEvent(
          new CustomEvent('FRAME_VALIDATION_CHANGED', {
            detail: event,
          })
        );
      }
    );
  }

  private updateCanSubmit() {
    this.canSubmit =
      this.validations.get('card-number') === true &&
      this.validations.get('cvv') === true &&
      this.validations.get('expiry-date') === true &&
      this.paymentForm.get('cardholderName')?.value;
  }
}