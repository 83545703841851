import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomErrorHandler } from './core/errors';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  FacebookLoginProvider,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { AuthInterceptor, STORAGE, WINDOW } from './core';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { ClipboardModule } from '@angular/cdk/clipboard';
import * as Sentry from '@sentry/angular';

export const InjectionTokens = [
  {
    provide: WINDOW,
    useValue: typeof window !== 'undefined',
  },
  {
    provide: STORAGE,
    useValue: typeof window !== 'undefined' && window.localStorage,
  },
  // {
  //     provide: JWT,
  //     useClass: JwtDecoder,
  // },
  // {
  //     provide: URL,
  //     useValue: URI(window.location),
  // },
];

export function facebookInit() {
  return {
    providers: [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.FACEBOOK_APP_ID!),
      },
    ],
    onError: (err: any) => {
      console.error(err);
    },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    ...InjectionTokens,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: facebookInit,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}