<div class="modal">
  <div class="modal__header">
    <h1>Daily spin</h1>
    <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
  </div>
  <div class="modal__content">
    <iframe
      title="Spin frame"
      src="https://pickerwheel.com/emb/2KDtd"
      width="100%"
      height="500px"
      scrolling="no"
      frameborder="0"
      style="background: #3f65ad">
    </iframe>
  </div>
</div>