import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UserBalanceDetails, UserInfo } from 'src/app/core/interfaces';
import { Currency } from '../../../shared';

const createInitialState = (): UserInfoStateEntry => {
  return {
    userLoaded: false,
    userIsLoading: false,
    user: {} as UserInfo,
    selectedCurrency: Currency.LUKC,
    balancesLoaded: false,
    balancesIsLoading: false,
    balances: [],
    hasCoinArea: false,
  };
};

export interface UserInfoStateEntry {
  userLoaded: boolean;
  userIsLoading: boolean;
  balancesLoaded: boolean;
  balancesIsLoading: boolean;
  user: UserInfo;
  balances: UserBalanceDetails[];
  selectedCurrency: Currency;
  hasCoinArea: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserInfoStore extends Store<UserInfoStateEntry> {
  constructor() {
    super(createInitialState());
  }

  public setUser(user: UserInfo): void {
    this.update({
      user: user,
      userLoaded: true,
      userIsLoading: false,
    });
  }

  public setBalances(balances: UserBalanceDetails[]): void {
    this.update({
      balances: balances,
      balancesLoaded: true,
      balancesIsLoading: false,
    });
  }

  public setCurrency(currency: Currency): void {
    this.update({
      selectedCurrency: currency,
    });
  }

  public setUserLoading(loading: boolean): void {
    this.update({
      userIsLoading: loading,
    });
  }

  public setBalanceLoading(loading: boolean): void {
    this.update({
      balancesIsLoading: loading,
    });
  }

  public setHasCoinArea(hasCoinArea: boolean): void {
    this.update({
      hasCoinArea: hasCoinArea,
    });
  }

  public clear(): void {
    this.update(createInitialState());
  }
}