<div class="modal blue">
  <div class="modal__header">
    <h1>Level {{ levelNumber }}</h1>
    <i (click)="close()" aria-describedby="Close" class="u_times-circle"></i>
  </div>

  <div class="modal__content">
    <div class="level-header" *ngIf="level">
      <div class="level-header__progress-bar">
        <app-progress-bar [clickable]="false"></app-progress-bar>
      </div>
      <div class="level-header__container">
        <div class="level" *ngIf="!level.Title">
          <span>Level {{ level.Level }}</span>
        </div>
        <div class="level" *ngIf="level.Title">
          <span>{{ level.Title }}</span>
        </div>
        <div class="accent">
          {{ playerLevel?.currentProgress | number: '1.2-2' }} % complete
        </div>
        <div class="accent">
          {{ playerLevel?.currentPoints }} of
          {{ playerLevel?.levelTarget }} Points
        </div>
      </div>
    </div>

    <div class="details">
      <app-level-details [levelNumber]="levelNumber"></app-level-details>
    </div>
  </div>
</div>